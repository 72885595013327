import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AppearInViewDiv from '../../components/misc/AppearInViewDiv';
import NavBarLogo from '../../components/misc/NavBarLogo';
import FaqDropdown from '../../components/misc/FaqDropdown';
import DownloadButton from '../../components/misc/DownloadButton';
import Footer from '../../components/misc/Footer';
import useLanguage from '../../hooks/useLanguage';
import { setLanguage } from '../../redux/slices/applicationSlice';
import { Language } from '../../types/requests';

export default function LandingPage(): JSX.Element {
  const { t } = useTranslation();
  const lang = useLanguage();
  const dispatch = useDispatch();

  const faq = ['free', 'device', 'privacy'];

  const descriptions = [
    t('page.landing.usecase.case.requestData'),
    t('page.landing.usecase.case.businessCard'),
    t('page.landing.usecase.case.introduction'),
    t('page.landing.usecase.case.card'),
    t('page.landing.usecase.case.phoneContact'),
    t('page.landing.usecase.case.deleteData'),
  ];

  return (
    <>
      <div className="w-full h-16 sticky top-0 bg-secondary-200 rounded-b-[20px] flex z-50 items-center px-4">
        <div className="flex w-full max-w-7xl justify-between items-center mx-auto ">
          <NavBarLogo />
          <img
            src={`${process.env.PUBLIC_URL}/images/flags/${
              lang === Language.NL ? Language.EN : Language.NL
            }.svg`}
            alt="Nederlands"
            onClick={() => dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))}
            className="w-6 h-6 cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full bg-secondary-50 h-full moments-landing flex flex-col px-5 items-center overflow-x-hidden">
        <AppearInViewDiv className="max-w-7xl flex justify-between w-full mx-auto sm:gap-[10%] py-12 sm:py-24 pb-24 items-center flex-col sm:flex-row gap-10">
          <div className="flex flex-col w-full gap-4">
            <h1 className="text-6xl font-bold">
              Better. Safe.
              <br /> Then Social.
            </h1>
            <div className="flex gap-4 flex-col">
              <p>{t('page.landing.firstSubtitle')}</p>
              <p>{t('page.landing.secondSubtitle')}</p>
            </div>

            <DownloadButton />
          </div>
          <div className="rounded-[20px] hidden w-full h-fit sm:flex items-center justify-end">
            <img
              alt="Not found"
              src={`${process.env.PUBLIC_URL}/images/misc/three_phones.png`}
              className="object-fit w-full"
            />
          </div>
        </AppearInViewDiv>

        {/* Inspirational */}
        <div className="w-full pb-14 my-6 flex flex-col items-center">
          <div className="w-full max-w-2xl text-center">
            <h3 className="text-primary text-xl font-bold">{t('page.landing.why.title')}</h3>
            <h2 className="text-4xl font-medium mb-4">{t('page.landing.why.subtitle')}</h2>
          </div>
        </div>

        {/* Whatt can you do with vera */}
        <AppearInViewDiv className="w-full full-width px-5">
          <div className="max-w-7xl flex flex-col justify-between w-full mx-auto gap-12 py-20 items-center md:px-10 md:flex-row">
            <div className="bg-primary-300 rounded-lg px-24 py-10 flex items-center justify-center w-full">
              <img
                className="w-[55%]"
                alt="Connect"
                src={`${process.env.PUBLIC_URL}/images/figures/phone.svg`}
              />
            </div>
            <div className="w-full flex flex-col">
              <h2 className="text-4xl font-medium">{t('page.landing.usecase.title')}</h2>
              <ul className="my-4">
                {descriptions.map((d) => (
                  <li className="flex gap-2">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/misc/check.svg`}
                      alt="check"
                      className="w-[15px] h-[15px] mt-[5px]"
                    />
                    {d}
                  </li>
                ))}
              </ul>
              <DownloadButton />
            </div>
          </div>
        </AppearInViewDiv>
        <div className="bg-primary-900 w-full full-width items-center px-5 py-10 justify-center flex">
          <div className="max-w-3xl gap-10 flex justify-between flex-col md:flex-row items-center ">
            <div className="flex w-full">
              <img
                alt="App"
                className="object-fit"
                src={`${process.env.PUBLIC_URL}/images/misc/two_phones.png`}
              />
            </div>
            <div className="flex flex-col w-full gap-4">
              <h3 className="text-secondary-50 text-5xl w-full">{t('page.landing.share.title')}</h3>
              <h2 className="text-secondary-50 mb-4 text-sm w-full">
                {t('page.landing.share.description')}
              </h2>
            </div>
          </div>
        </div>

        {/* Different applications */}
        <div className="w-full max-w-5xl mx-auto flex flex-col mt-20 mb-12 sm:mb-20">
          <h3 className="sm:text-4xl text-3xl font-medium mb-4 mx-auto text-center">
            {t('page.landing.application.title')}
          </h3>
          <p className="max-w-xl mx-auto text-center">{t('page.landing.application.subtitle')}</p>
          <div className="flex flex-col md:flex-row gap-8 mt-8">
            <div className="w-full flex flex-col text-center">
              {/* Navigate to veracommunity.app when clicked */}
              <div
                onClick={() => window.open('https://veramoments.app')}
                className="h-72 flex items-center cursor-pointer justify-center rounded-lg bg-secondary p-8">
                <img
                  alt="Moments"
                  src={`${process.env.PUBLIC_URL}/images/figures/heart.svg`}
                  className="h-full object-fit "
                />
              </div>
              <h3 className="font-bold text-lg mt-2">Vera Moments</h3>
              <p className="text-sm">{t('page.landing.product.moments')}</p>
            </div>
            <div className="w-full flex flex-col text-center">
              <div
                onClick={() => window.open('https://veracommunity.app')}
                className="h-72 cursor-pointer flex items-center justify-center rounded-lg  bg-secondary p-8">
                <img
                  alt="Community"
                  src={`${process.env.PUBLIC_URL}/images/figures/mess.svg`}
                  className="h-full object-fit"
                />
              </div>
              <h3 className="font-bold text-lg mt-2">Vera Community</h3>
              <p className="text-sm">{t('page.landing.product.community')}</p>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div className="max-w-7xl pb-20 sm:pt-10 flex mx-auto gap-10 flex-col md:flex-row">
          <div className="w-full">
            <h3 className="text-primary text-xl font-bold">{t('page.landing.faq.subtitle')}</h3>
            <h2 className="text-4xl font-medium mb-4">{t('page.landing.faq.title')}</h2>
            <p>{t('page.landing.faq.description')}</p>
          </div>
          <div className="w-full flex flex-col gap-4">
            {faq.map((f) => (
              <FaqDropdown
                key={f}
                question={t(`page.landing.faq.${f}.question`)}
                answer={t(`page.landing.faq.${f}.answer`)}
              />
            ))}
            <p className="mx-auto mt-4">
              {t('page.landing.faq.email')}
              <span
                className="text-primary hover:text-primary-300 cursor-pointer underline underline-offset-4"
                onClick={() => window.open('mailto:?to=support@veraconnect.nl', '_blank')}>
                support@veraconnect.nl
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
