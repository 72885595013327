import React from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectComWebsite } from '../../utils/appUtils';

/*
 * The logo for the navbar, small if phone big if pc.
 * @returns {JSX.Element} - The logo component.
 */
export default function NavBarLogo(): JSX.Element {
  const navigate = useNavigate();
  return (
    <div
      data-testid="nav-bar-logo "
      onClick={() => redirectComWebsite()}
      className="flex flex-shrink-0 items-center cursor-pointer">
      <img
        data-testid="nav-bar-logo-big"
        className=" h-10 w-auto "
        src={`${process.env.PUBLIC_URL}/logo.svg`}
        alt="Vera Connect"
      />
    </div>
  );
}
