import React, { useEffect, useState } from 'react';
import { HiOutlineBan } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { FaUserPlus } from 'react-icons/fa6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Introduction, InviteType } from '../../../types/invites';
import useAuth from '../../../hooks/useAuth';
import { authenticationService, connectionService, invitationService } from '../../../services';
import { ProfileField } from '../../../types/profile';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import InvalidAuthBody from '../../../components/misc/InvalidAuthBody';
import InvalidAuthHeader from '../../../components/headers/InvalidAuthHeader';
import { getInitials } from '../../../utils/stringUtils';
import UserDataForm from '../../../components/forms/userData/UserDataForm';
import useDeleteParams from '../../../hooks/useDeleteParams';
import NoDataSharedModal from '../../../components/modals/NoDataSharedModal';
import Button from '../../../components/misc/Button';
import ApiImage from '../../../components/misc/ApiImage';
import { SuccessPageState } from '../../../types/navigation';
import { redirectComWebsite } from '../../../utils/appUtils';

export default function IntroductionsPage(): JSX.Element {
  const [params] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [introductions, setIntroductions] = useState<Introduction[]>();
  const [selected, setSelected] = useState<string>();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const link = params.get('link');
  useDeleteParams('link');

  if (link && !loggingIn) {
    setLoggingIn(true);
    authenticationService
      .loginMagic(link)
      .catch(() => {})
      .finally(() => setLoggingIn(false));
  }

  useEffect(() => {
    if (!auth || loggingIn) return;
    invitationService.getIntroductions().then((i): void => {
      if (!i.length) {
        redirectComWebsite();
        return;
      }
      setIntroductions(i);
    });
  }, [JSON.stringify(auth), loggingIn]);

  useEffect(() => {
    if (introductions && !introductions.length) {
      const state: SuccessPageState = {
        translationKey: 'page.common.success.message.introduction',
      };
      navigate('/success', { state });
    }
  }, [introductions?.length]);

  if (!auth)
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InvalidAuthHeader type={InviteType.INTRODUCTION} />
        </ResponsePageWrapper.Header>
        <InvalidAuthBody invitationId="" inviteType={InviteType.INTRODUCTION} />
      </ResponsePageWrapper>
    );

  return (
    <ResponsePageWrapper>
      {!selected ? (
        <IntroductionOverview
          introductions={introductions || []}
          setSelected={setSelected}
          setIntroductions={setIntroductions}
        />
      ) : (
        <AcceptIntroduction
          introduction={introductions?.find((i) => i.id === selected)!}
          introductions={introductions || []}
          setSelected={setSelected}
          setIntroductions={setIntroductions}
        />
      )}
    </ResponsePageWrapper>
  );
}

interface AcceptIntroductionProps {
  introduction: Introduction;
  introductions: Introduction[];
  setSelected: (id: string | undefined) => void;
  setIntroductions: (introductions: Introduction[]) => void;
}

function AcceptIntroduction({
  introduction,
  introductions,
  setSelected,
  setIntroductions,
}: AcceptIntroductionProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setModalOpen(true);
    else acceptIntroduction();
  };

  const acceptIntroduction = async () => {
    await connectionService.acceptIntroduction(
      introduction.id,
      selectedData.map((f) => f.id!),
    );
    setIntroductions(introductions.filter((i) => i.id !== introduction.id));
    setSelected(undefined);
  };

  return (
    <>
      <div className="flex flex-col">
        <h2 className="font-medium text-lg">{t('page.introductions.selected.title')}</h2>
        <p className="text-sm">
          {t('page.introductions.selected.subtitle', {
            name: introduction.introducedAlias.replace('+', ' '),
          })}
        </p>
      </div>
      <UserDataForm
        selectedData={selectedData || []}
        setSelectedData={setSelectedData}
        hasSelectAll
      />
      <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
        <Button className="underline" onClick={() => setSelected(undefined)}>
          {t('general.cancel')}
        </Button>
        <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
          {t('page.shared.shareData')}
        </Button>
      </div>
      <NoDataSharedModal
        open={modalOpen}
        setOpen={setModalOpen}
        requesterName={introduction.introducedAlias.split('+')[0] || ''}
        handleComplete={acceptIntroduction}
      />
    </>
  );
}

interface IntroductionOverviewProps {
  introductions: Introduction[];
  setSelected: (id: string) => void;
  setIntroductions: (introductions: Introduction[]) => void;
}
function IntroductionOverview({
  introductions,
  setSelected,
  setIntroductions,
}: IntroductionOverviewProps): JSX.Element {
  const { t } = useTranslation();

  const declineIntroduction = async (introductionId: string) => {
    await connectionService.declineIntroduction(introductionId);
    setIntroductions(introductions.filter((i) => i.id !== introductionId));
  };

  return (
    <>
      {' '}
      <h1 className="text-2xl font-medium tracking-tight font-serif">
        {t('page.introductions.overview.title')}
      </h1>
      <p className="tracking-tight">{t('page.introductions.overview.subtitle')}</p>
      {!introductions.length && (
        <p className="text-sm mt-2">{t('page.introductions.overview.empty')}</p>
      )}
      <div className="flex flex-col w-full flex-1 divide-y divide-secondary-200 max-h-[500px] overflow-x-scroll border-2 rounded-3xl mt-4 border-secondary-200">
        {introductions.map((i) => (
          <div className="w-full  text-sm flex flex-col px-2 py-4 gap-1">
            <div className="flex gap-2 items-center">
              {i.introducerPicture ? (
                <ApiImage
                  src={i.introducerPicture}
                  alt={i.introducerAlias.replace('+', ' ')}
                  className="h-10 w-10 bg-secondary rounded-lg border border-secondary-200"
                />
              ) : (
                <div className="h-10 w-10 border rounded-lg text-lg text-secondary-50 bg-secondary flex flex-none items-center justify-center">
                  {getInitials(i.introducerAlias)}
                </div>
              )}
              <p>
                <strong className="font-semibold">{i.introducerAlias.replace('+', ' ')}</strong>
                {t('page.introductions.overview.introduced')}
                <strong className="font-semibold">{i.introducedAlias.replace('+', ' ')}</strong>
              </p>
            </div>
            <div className="flex">
              <div className="h-1 w-10 mr-2" />
              <Button variant="primary" onClick={() => setSelected(i.id)}>
                <FaUserPlus className="w-4 h-4" />
                {t('general.accept')}
              </Button>
              <Button className="underline" onClick={() => declineIntroduction(i.id)}>
                <HiOutlineBan className="w-5 h-5" strokeWidth="2" />
                {t('general.decline')}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
