import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  authenticationService,
  connectionService,
  linkService,
  personalLinkService,
  profileService,
} from '../../services';
import { Label, ProfileDataType, ProfileField } from '../../types/profile';
import { isEmptyField, isValidProfileFields } from '../../utils/validationUtils';
import NameInput from '../../components/forms/profile/NameInput';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import EmailInput from '../../components/forms/profile/EmailInput';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import ProfileFieldForm from '../../components/forms/profile/ProfileFieldForm';
import * as formatUtils from '../../utils/formatUtils';
import { InviteType, PersonalLinkData } from '../../types/invites';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import Button from '../../components/misc/Button';
import {
  InvitationDetails,
  UserAccountCreationChannel,
  UserAccountCreationEvent,
} from '../../analytics/models';
import { redirectComWebsite } from '../../utils/appUtils';

export default function UnauthenticatedPersnalLinkPage(): JSX.Element {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const linkId = params.get('linkId');
  const link = params.get('link');

  if (link) authenticationService.loginMagic(link);

  const [alias, setAlias] = useState<string>('');
  const [profileFields, setProfileFields] = useState<ProfileField[]>([
    { label: Label.GENERAL, description: '', dataType: ProfileDataType.EMAIL },
    {
      label: Label.GENERAL,
      description: '',
      dataType: ProfileDataType.PHONENUMBER,
      phoneNumber: { prefix: '', suffix: '', countryCode: '' },
    },
  ]);
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [linkData, setLinkData] = useState<PersonalLinkData>();

  const emailField = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL);

  useEffect(() => {
    linkService
      .getLinkInfo(linkId as string)
      .then((linkInfo) => setLinkData(linkInfo.data as PersonalLinkData))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        redirectComWebsite();
      });
  }, []);

  const validateFields = (): boolean => {
    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) {
      toast.error(t('toast.error.field.name'));
      return false;
    }
    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }
    if (isEmptyField(profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!)) {
      toast.error(t('toast.error.field.email'));
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      setValidate(false);
      return;
    }

    const event: UserAccountCreationEvent<InvitationDetails> = {
      channel: UserAccountCreationChannel[UserAccountCreationChannel.PUBLIC_INVITE],
      details: { inviteLink: linkId || '' },
    };

    authenticationService.register(alias, emailField?.email || '', event).then(async (register) => {
      const filledProfileFields = profileFields.filter(
        (x) => x.dataType !== ProfileDataType.EMAIL && !isEmptyField(x),
      );

      if (filledProfileFields) {
        await profileService.addFields(filledProfileFields);
      }

      const sharedProps = await profileService
        .getUserData()
        .then((data) => data.map((d) => +(d.id || '')));
      await personalLinkService.usePersonalLink(linkId as string, sharedProps);
      const state: SuccessPageState = {
        translationKey: 'page.common.success.message.personalLink',
        translationOptions: {
          name: linkData?.firstName || '',
        },
      };
      navigate('/success', { state });
      register();
    });
  };

  const handleLogin = async () => {
    const state: LoginPageState = { personalLinkId: linkId as string };
    navigate('/login', { state });
  };

  const setProfileFieldByType = (field: ProfileField) => {
    setProfileFields(
      profileFields.map(
        (f: ProfileField): ProfileField => (f.dataType === field.dataType ? field : f),
      ),
    );
  };

  return (
    <ResponsePageWrapper handleLogin={handleLogin}>
      <ResponsePageWrapper.Header>
        <InviteHeader
          requesterName={linkData?.firstName || ''}
          message={t('page.personalLink.title', { name: linkData?.firstName })}
        />
      </ResponsePageWrapper.Header>

      <div className="flex flex-col">
        <h2 className="font-medium text-lg">{t('page.personalLink.register')}</h2>
      </div>
      <div className="gap-4 flex flex-col mt-4">
        <NameInput label alias={alias} setAlias={setAlias} mandatory validate={validate} />

        <EmailInput
          field={emailField!}
          emailCheck
          label
          validate={validate}
          setAccountExists={setAccountExists}
          setField={setProfileFieldByType}
          mandatory
        />

        {accountExists ? (
          <span className="text-sm text-red-500">
            {t('page.shared.emailInUse')}{' '}
            <strong className="cursor-pointer underline" onClick={handleLogin}>
              {t('page.shared.logInHere')}
            </strong>
            .
          </span>
        ) : (
          ''
        )}

        {profileFields.map((field) => {
          return field.dataType === ProfileDataType.EMAIL ? (
            <></>
          ) : (
            <div
              key={field.dataType}
              className={field.dataType === ProfileDataType.BIRTHDATE ? 'w-1/2' : ''}>
              <ProfileFieldForm
                field={field}
                canBeEmpty
                label
                validate={validate}
                setField={setProfileFieldByType}
              />
            </div>
          );
        })}
      </div>
      <TermsAndConditions
        className="mt-4"
        checked={terms}
        setChecked={setTerms}
        name={linkData?.firstName}
      />
      <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
        <Button variant="primary" className="px-[32px]" onClick={handleSave}>
          {t('page.personalLink.submit')}
        </Button>
      </div>
    </ResponsePageWrapper>
  );
}
