import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import CommunityInviteHeader from '../../../components/headers/CommunityInviteHeader';
import { CommunityInvite, InviteType } from '../../../types/invites';
import useAuth from '../../../hooks/useAuth';
import { authenticationService, connectionService, invitationService } from '../../../services';
import useDeleteParams from '../../../hooks/useDeleteParams';
import { InvalidAccountResponse } from '../../../types/responses';
import InvalidAuthHeader from '../../../components/headers/InvalidAuthHeader';
import InvalidAuthBody from '../../../components/misc/InvalidAuthBody';
import { SuccessPageState } from '../../../types/navigation';
import Button from '../../../components/misc/Button';
import { redirectComWebsite } from '../../../utils/appUtils';

export default function CommunityContactInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const link = params.get('link');
  const invitationId = params.get('invitationId');

  if (link) authenticationService.loginMagic(link).catch(() => {});
  useDeleteParams('link');

  const [invalidAccountInfo, setInvalidAccountInfo] = useState<InvalidAccountResponse>();
  const [invite, setInvite] = useState<CommunityInvite>();

  useEffect(() => {
    if (!invitationId) {
      toast.error(t('toast.error.general.invalidInvite'));
      redirectComWebsite();
      return;
    }

    invitationService
      .getCommunityContactInvite(invitationId)
      .then((inv) => {
        setInvite(inv);
        setInvalidAccountInfo(undefined);
      })
      .catch((err: AxiosError<InvalidAccountResponse>): void => {
        if (err.response?.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          setInvalidAccountInfo(err.response.data);
        } else {
          toast.error(t('toast.error.general.inviteNotFound'));
          redirectComWebsite();
        }
      });
  }, [JSON.stringify(auth)]);

  const handleSubmit = async (accepted: boolean): Promise<void> => {
    await connectionService.respondToCommunityContactInvite(
      invitationId!,
      invite!.communityId,
      accepted,
    );
    const state: SuccessPageState = {
      translationKey: `page.common.success.message.communityInvite.${
        accepted ? 'accepted' : 'rejected'
      }`,
      translationOptions: {
        communityName: invite?.communityName ?? '',
      },
      communityInvite: accepted ? invite! : undefined,
    };
    if (accepted) toast.success(t('toast.success.communityJoined'));
    navigate('/success', { state });
  };

  if (invalidAccountInfo)
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InvalidAuthHeader type={InviteType.COMMUNITY_CONTACT_INVITE} />
        </ResponsePageWrapper.Header>
        <InvalidAuthBody
          info={invalidAccountInfo}
          invitationId={invitationId!}
          inviteType={InviteType.COMMUNITY_CONTACT_INVITE}
        />
      </ResponsePageWrapper>
    );

  return (
    <ResponsePageWrapper>
      <ResponsePageWrapper.Header>
        <CommunityInviteHeader invite={invite!} submessage={t('header.invalidAuth.message')} />
      </ResponsePageWrapper.Header>
      <div className="gap-4 flex-1 h-full flex flex-col">
        <div className="flex flex-col">
          <h2 className="font-medium text-lg">
            {t('page.communityInvite.title', { name: invite?.communityName })}
          </h2>
          <p className="text-sm">
            {t('page.communityInvite.authMessage', {
              communityName: invite?.communityName,
              inviterName: invite?.inviterFirstName,
            })}
          </p>
        </div>
        <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
          <Button className="underline" onClick={() => handleSubmit(false)}>
            {t('general.decline')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={() => handleSubmit(true)}>
            {t('page.communityInvite.submit', { communityName: invite?.communityName })}
          </Button>
        </div>
      </div>
    </ResponsePageWrapper>
  );
}
