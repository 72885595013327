import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { linkService } from '../../services';
import { linkInfoToLink } from '../../utils/formatUtils';
import { redirectComWebsite } from '../../utils/appUtils';

export default function InviteRedirectPage(): JSX.Element {
  const { linkId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const invalidNavigate = (): void => {
    toast.error(t('toast.error.general.invalidInvite'));
    redirectComWebsite();
  };

  useEffect(() => {
    if (!linkId) {
      invalidNavigate();
      return;
    }

    linkService
      .getLinkInfo(linkId)
      .then((linkInfo) => {
        const link = linkInfoToLink(linkInfo);
        navigate(link, { replace: true });
      })
      .catch(invalidNavigate);
  }, []);

  return <></>;
}
